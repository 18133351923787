import React, { useContext } from 'react'
import { AuthContext } from "../../context/AuthContext";
import AnalyticsListing from './AnalyticsListing';
import ChannelAnalyticsListing from './ChannelAnalyticsListing';
export default function Index() {
    const auth = useContext(AuthContext);
    return (
        <>
            {auth?.userData?.is_subadmin === true && <ChannelAnalyticsListing />}
            {auth?.userData?.is_subadmin === false && <AnalyticsListing />}
        </>
    )
}
