import { Button, Grid, Stack, Typography } from '@mui/material'
import { default as React, useEffect, useContext, useCallback } from 'react'
import { AiOutlineDashboard } from "react-icons/ai"
import { GoPackageDependents } from "react-icons/go"
import { useNavigate } from 'react-router-dom'
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import ReportSection from './ReportSection'
import NotificationSection from './notifications/NotificationSection'
import { AuthContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";

import { getParticularAdminDashboardAPI, resetgetparticularAdminDashboard } from "../../store/admin/getDashboard";


export default function MainHome() {

    const navigate = useNavigate()
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const adminDashboardData = useSelector((state) => state?.getparticularDetailsAdminDashboard);

    const handleOrderNow = () => {
        navigate('/courses')
    }

    const dashboardApiCall = useCallback(() => {
        dispatch(getParticularAdminDashboardAPI())
    }, [dispatch])

    useEffect(() => {
        dashboardApiCall()
        return () => {
            dispatch(resetgetparticularAdminDashboard())
        }
    }, [dispatch, dashboardApiCall])
    return (
        <>
            <Appbar />
            <Drawer />
            <Body>

                <Grid container spacing={2}>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Stack spacing={2}>
                            <Grid container alignItems='center'>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <AiOutlineDashboard size={30} />
                                        <Typography>Dashboard</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <NotificationSection dashboardData={adminDashboardData?.result} />
                        </Stack>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Stack spacing={2}>
                            <Button startIcon={<GoPackageDependents />} size='medium' onClick={handleOrderNow} variant='outlined' sx={{ bgcolor: '#fff' }}>Go To Course</Button>
                            <ReportSection />
                        </Stack>
                    </Grid>
                </Grid>
            </Body>
        </>
    )
}
