import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import BackdropUI from "../../ui/Backdrop";
import { channelcodeApi } from "../../store/channelCode/channelcodeList";
import { addchannelApi } from "../../store/channel/addChannelList";
import { EditchannelApi, resetchannel } from "../../store/channel/editChannelList";
import { getParticularchannelAPI, resetgetparticularchannel } from "../../store/channel/channelById";
import { AuthContext } from "../../context/AuthContext";
import { DevTool } from "@hookform/devtools";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



export default function EditChannelDetails() {
  const theme = useTheme();
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const channelcodeList = useSelector((state) => state?.channelcodeList);
  const channelcodedetails = useSelector((state) => state?.getparticularDetailschannel);
  const shortVideoEdit = useSelector((state) => state?.editChannelCode);
  const shortVideoAdd = useSelector((state) => state?.addchannelcode);
  const { result, loiding } = channelcodedetails;
  const auth = useContext(AuthContext);
  const schema = yup
    .object({
      channel_name: yup.string().required("Please provide channel ").trim()
        .matches(/^[^!@#$%^&*+=<>:;|~]*$/, {
          message: 'No symbols should be there in channel code'
        })
      ,
      channel_name_hi: yup.string().required("Please provide channel ").trim()
        .matches(/^[^!@#$%^&*+=<>:;|~]*$/, {
          message: 'No symbols should be there in channel code'
        })
      ,

      assign_channel_code: auth?.userData?.is_subadmin ? yup.number().optional() : yup.number().required(),
    })
    .required();
  const dispatch = useDispatch();
  const refInput = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });
  const [type, setType] = React.useState([]);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      dispatch(resetchannel());
      dispatch(resetgetparticularchannel())
    }
  }, [])
  useEffect(() => {
    dispatch(channelcodeApi());
    if (params.id) {
      dispatch(getParticularchannelAPI(params.id));
    }
  }, [params.id, getParticularchannelAPI, channelcodeApi, dispatch]);

  const handleUpdateForm = async (data) => {
    const newobj = {
      ...data, assign_channel_code: data?.assign_channel_code
    }

    console.log('newObj', newobj)
    if (params.id) {
      let obj = { ...newobj, id: params.id };
      await dispatch(EditchannelApi(obj));
      if (!shortVideoEdit?.loading) {
        navigate("/channel-list");

      }
    } else {
      await dispatch(addchannelApi(newobj));
      if (!shortVideoAdd?.loading) {
        navigate("/channel-list");
      }
    }
  };
  useEffect(() => {
    let defalutValues = {};
    if (params.id) {
      defalutValues["channel_name"] = result?.channel_name;
      defalutValues["channel_name_hi"] = result?.channel_name_hi;
      defalutValues["assign_channel_code"] = result?.assign_channel_code;
    }
    reset(defalutValues);
  }, [result, channelcodeList, reset, params.id]);
  useEffect(() => {
    setType(channelcodeList?.result?.result)
  }, [channelcodeList]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>

                <Typography>Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Channel Name </InputLabel>
                        <OutlinedInput
                          {...register("channel_name")}
                          label="channel_name"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.channel_name?.message}
                          defaultValue={watch('channel_name', "")}
                        />
                        <FormHelperText error>
                          {errors?.channel_name?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Channel Name Hindi </InputLabel>
                        <OutlinedInput
                          {...register("channel_name_hi")}
                          label="channel_name_hindi"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.channel_name_hi?.message}
                          defaultValue={watch('channel_name_hi', "")}
                        />
                        <FormHelperText error>
                          {errors?.channel_name_hi?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="assign_channel_code" shrink>
                          Channel Code
                        </InputLabel>
                        <Controller
                          name="assign_channel_code"
                          label="Channel Code"
                          control={control}
                          disabled={auth?.userData?.is_subadmin}
                          defaultValue={watch('assign_channel_code', "")}
                          value={watch('assign_channel_code', "")}
                          render={({ field }) => (
                            <Select
                              {...field}
                              defaultValue={watch('assign_channel_code', "")}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('assign_channel_code', value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-single-chip" label="Channel Code" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  <Chip key={selected} label={type?.find(obj => obj.id === selected)?.channel_code_name || ""} />
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {type?.map((obj) => (
                                <MenuItem
                                  key={obj.channel_code_name}
                                  value={obj.id}
                                  style={getStyles(obj.channel_code_name, [watch('assign_channel_code', '')], theme)}
                                >
                                  {obj.channel_code_name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />

                        <FormHelperText error>
                          {errors?.assign_channel_code?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />
      <DevTool control={control} />
    </>
  );
}
