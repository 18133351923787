import { Button, Grid, Stack, Typography } from '@mui/material'
import { default as React, useEffect, useContext, useCallback } from 'react'
import { AiOutlineDashboard } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import ChannelDashboardData from './ChannelDashboardData'
import { AuthContext } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";

import { getParticularchannelAdminDashboardAPI, resetgetparticularchannelAdminDashboard } from "../../store/channel-admin/getDashboard";


export default function ChannelHome() {

    const navigate = useNavigate()
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state?.getparticularDetailschannelAdminDashboard);

    const handleOrderNow = () => {
        navigate('/courses')
    }

    const dashboardApiCall = useCallback(() => {
        dispatch(getParticularchannelAdminDashboardAPI());
    }, [dispatch])

    useEffect(() => {
        dashboardApiCall()
        return () => {
            dispatch(resetgetparticularchannelAdminDashboard())
        }
    }, [dispatch, dashboardApiCall])
    return (
        <>
            <Appbar />
            <Drawer />
            <Body>

                <Grid container spacing={2}>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Stack spacing={2}>
                            <Grid container alignItems='center'>
                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <AiOutlineDashboard size={30} />
                                        <Typography>Dashboard</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <ChannelDashboardData dashboardData={dashboardData?.result} />
                        </Stack>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4}>
                    </Grid>
                </Grid>
            </Body>
        </>
    )
}
