import { default as React, useEffect, useContext, useCallback } from 'react'
import { AuthContext } from "../../context/AuthContext";
import MainHome from './MainHome';
import ChannelHome from './ChannelHome';
export default function Index() {
  const auth = useContext(AuthContext);
  return (
    <>
      {(auth?.userData && auth?.userData?.is_subadmin === true) && <ChannelHome />}
      {(auth?.userData && auth?.userData?.is_subadmin === false) && <MainHome />}
    </>
  )
}
